import React from "react";
import LcLayout from "./src/components/learningCenter/lcLayout";
import FadeInOnFontLoad from "./src/components/shared/fadeInOnFontLoad";
import { ReferralModalTSB } from "./src/components/shared/referralModalTSB";

export const wrapRootElement = ({ element }) => <FadeInOnFontLoad>{element}</FadeInOnFontLoad>;

export const wrapPageElement = ({ element, props }) => {
  const path = props?.location?.pathname;
  const isLearningCenterPath = path && path.startsWith("/learn/");
  const Layout = isLearningCenterPath ? LcLayout : React.Fragment;

  return (
    <Layout {...props}>
      <ReferralModalTSB />
      {element}
    </Layout>
  );
};
