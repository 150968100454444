import React, { useEffect } from "react";
import * as c from "./referralModalTSB.module.scss";
import useModal from "@hooks/useModal";
import { Button } from "@components/shared/redesign/button";
import { StringParam, useQueryParam } from "use-query-params";

const ReferralModalTSB = () => {
  const [utmSource] = useQueryParam("utm_source", StringParam);

  useEffect(() => {
    if (utmSource === "thesalonbusiness") openModal();
  }, [utmSource]);

  const { Modal, openModal, closeModal, isModalOpen } = useModal({ backgroundColor: "grey" });

  return (
    <>
      {isModalOpen && (
        <Modal>
          <div className={c.container}>
            <h2 className={c.title}>Hey The Salon Business friend!  👋</h2>
            <p className={c.text}>
              We’re so excited to see a member of The Salon Business community here! Take a look around and start a
              trial when you’re ready. As you were referred by The Salon Business, you’ll get two months with Mangomint
              for free if you decide it is the right fit.
            </p>
            <Button asHtmlButton={true} onClick={(e) => closeModal(e)} className={c.closeButton}>
              Sounds good!
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export { ReferralModalTSB };
